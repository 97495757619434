import { SpecialProductDimensionService } from './../../services/special-product-dimension-service';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import * as moment from 'moment';
import { DisinfectionTypeService } from 'services/disinfection-type-service';
import { ErrorService } from 'services/error-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetService } from 'services/net-service';
import { ServiceService } from 'services/service-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { IndustryService } from 'services/industry-service';
import { DetergentTypeService } from 'services/detergent-type-service';
import { InvoiceBadge } from 'components/badge/invoice-badge';
import { I18N } from 'aurelia-i18n';
import { ServiceConsumptionWashingService } from 'services/service-consumption-washing-service';
import { ServiceConsumptionWashingModel } from 'features/service-washing-consumption-create-edit';
import { ServiceInvoiceStatus } from 'models/ServiceInvoiceStatus';

@autoinject
export class ServiceDetailWash {
  private net: Models.Net;
  private service: Models.Service;
  private nextTabIndex: number = null;

  private serviceConsumptionWashing: Models.ServiceConsumptionWashing[];

  protected headers = [];
  protected serviceInvoiceStatus: ServiceInvoiceStatus;

  private weightNewNetString = '';
  
  private invoiceConsumptionWashingStatus: string;

  @bindable private serviceId: number;
  @bindable private netId: number;

  specialProductDimension: Models.SpecialProductDimension;
  netdimension: Models.NetDimension;
  originalObject: Models.Service;
  locked = false;
  isSpecialProduct: boolean | undefined;
  isFisheryServiceStation: boolean | undefined;
  @bindable isDialog = false;

  constructor(
    private t: I18N,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netService: NetService,
    private router: Router,
    private toastService: ToastService,
    private utility: Utility,
    private serviceService: ServiceService,
    private netDimensionService: NetDimensionService,
    private specialProductDimensionService: SpecialProductDimensionService,
    private disinfectionTypeService: DisinfectionTypeService,
    private detergentTypeService: DetergentTypeService,
    private serviceConsumptionWashingService: ServiceConsumptionWashingService,
    private industryService: IndustryService
  ) {
    this.createHeaders();
  }

  protected attached() {
    if (this.serviceId && this.netId) {
      void this.activate({ Id: this.serviceId, NetId: this.netId });
    }
  }

  protected invoiceStatusMaterialUse = undefined;

  private async activate(params: { Id: number; NetId: number }) {
    this.serviceId = params.Id;
    this.netId = params.NetId;

    this.getService(params.Id);
    this.getNet(params.NetId);
    await this.getSericeWashing(this.serviceId || params.Id);
    void this.getServiceInvoiceStatus(params.Id);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private async getServiceInvoiceStatus(serviceId: number) {
    try {
      this.serviceInvoiceStatus = await this.serviceService.getServiceInvoiceStaatus(serviceId);
    } catch (error) {
      //
    }
  }

  protected isCreating = false;
  setIsCreating(isCreating: boolean) {
    this.editingConsumptionWashing = undefined;
    this.isCreating = isCreating;
  }

  editingConsumptionWashing: number | undefined = undefined;
  setEditingConsumptionWashing(consumptionWashingId: number) {
    this.isCreating = false;
    this.editingConsumptionWashing = consumptionWashingId;
  }

  private saveService() {
    this.service.DetergentType = null;
    this.serviceService
      .put(this.service, this.service.Id)
      .then(() => {
        this.toastService.showSuccess('service.washSaved');
        this.eventAggregator.publish('serviceUpdated');
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private saveServiceAndUpdateStatus(status: number) {
    this.serviceService
      .updateServiceStatus(status, this.service.Id)
      .then((res) => {
        if (!this.service.WashedDate) {
          this.service.WashedDate = res.WashedDate;
        }
        if (!this.service.MeasuredDate) {
          this.service.MeasuredDate = res.MeasuredDate;
        }
        this.toastService.showSuccess('service.serviceStatusChanged');
        this.saveService();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateCircumference() {
    this.service.CircumferenceMeasured =
      (Number.parseFloat(`${this.service?.LengthMeasured}`) || 0) +
      (Number.parseFloat(`${this.service?.LengthMeasured2}`) || 0) +
      (Number.parseFloat(`${this.service?.WidthMeasured}`) || 0) +
      (Number.parseFloat(`${this.service?.WidthMeasured2}`) || 0);
  }

  private async getIndutry(id: number) {
    try {
      const industry = await this.industryService.get(id);
      this.isFisheryServiceStation = industry.Name == 'Fishery';
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private getService(id: number) {
    this.serviceService
      .get(id + '?$expand=ServiceStation')
      .then((res) => {
        this.getIndutry(res.ServiceStation.IndustryId);
        this.service = res;
        this.locked = res.Locked ? res.Locked : false;
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNet(id: number) {
    this.netService
      .getNetInfo(id)
      .then((res) => {
        this.net = res;
        this.getDimensions(res);
        this.isSpecialProduct = this.net.IsSpecialProduct;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async getSericeWashing(serviceId: number) {
    const result = await this.serviceConsumptionWashingService.getAll(
      '?$expand=DisinfectionType($expand=Product),DetergentType($expand=Product)&$filter=ServiceId eq ' + serviceId
    );
    this.serviceConsumptionWashing = result;
    const invoiceConsumptionWashingStatusList = this.serviceConsumptionWashing.map((x) =>
      x.ServiceInvoiceId ? true : false
    );
    this.invoiceConsumptionWashingStatus = InvoiceBadge.GetInvoiceType(invoiceConsumptionWashingStatusList);
  }

  protected async onSave(data: ServiceConsumptionWashingModel) {
    if (data.Id) {
      await this.doUpdateWashingConsumption(data);
    } else {
      await this.doCreateWashingConsumption(data);
    }
  }

  protected async doCreateWashingConsumption(data: ServiceConsumptionWashingModel) {
    await this.serviceConsumptionWashingService.post(data as Models.ServiceConsumptionWashing);
    await this.onSaved('create');
  }

  protected async doUpdateWashingConsumption(data: ServiceConsumptionWashingModel) {
    await this.serviceConsumptionWashingService.put(data as Models.ServiceConsumptionWashing, data.Id);
    await this.onSaved('update');
  }

  protected async onSaved(type: 'create' | 'update') {
    if (type === 'create') {
      this.setIsCreating(false);
      this.toastService.showSuccess(this.t.tr('general.created'));
      await this.getSericeWashing(this.serviceId);
    } else {
      this.setEditingConsumptionWashing(undefined);
      this.toastService.showSuccess(this.t.tr('general.updated'));
      await this.getSericeWashing(this.serviceId);
    }
  }

  async onDelte(id: number) {
    try {
      await this.serviceConsumptionWashingService.delete(id);
      this.toastService.showSuccess(this.t.tr('general.entryDeleted'));
      await this.getSericeWashing(this.serviceId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private getDimensions(net: Models.Net) {
    if (net.NetDimensionId) {
      this.netDimensionService
        .get(net.NetDimensionId + '?$expand=DimensionClass')
        .then((res) => {
          this.netdimension = res;
          this.createWeightNewNetString(res?.Weight);
        })
        .catch((err) => this.errorService.handleError(err));
    } else if (net.SpecialProductDimensionId) {
      this.specialProductDimensionService
        .get(net.SpecialProductDimensionId)
        .then((res) => {
          this.specialProductDimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  setWashAndMeasurementsFileId(event: { detail: { id: number } }) {
    this.service.WashAndMeasurementsFileId = event.detail.id;
  }

  removeWashAndMeasurementsFileId() {
    this.service.WashAndMeasurementsFileId = null;
  }

  public canDeactivate() {
    if (!this.originalObject) {
      return true;
    }

    this.originalObject.WashedDate = moment.default(this.originalObject.WashedDate).format('YYYY-MM-DD') as any;
    this.service.WashedDate = moment.default(this.service.WashedDate).format('YYYY-MM-DD') as any;

    if (!this.utility.areEqual(this.service, this.originalObject)) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: { header: 'dialog.pleaseConfirmHeader', message: 'dialog.unsavedChangesText' },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // Save the service and let that function handle the rest of the logic
              this.saveService();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private updateOriginalObject() {
    this.originalObject = JSON.parse(JSON.stringify(this.service));
  }

  createWeightNewNetString(weight: number) {
    if (weight) {
      this.weightNewNetString = `${this.t.tr('service.weightNewNet')}: ${this.netdimension.Weight} kg`;
    }
  }

  createHeaders() {
    this.headers = [
      { title: this.t.tr('service.detergentType') },
      { title: this.t.tr('general.articleno') },
      { title: this.t.tr('service.ConsumptionLiters') },
      { title: this.t.tr('service.disinfectionType') },
      { title: this.t.tr('general.articleno') },
      { title: this.t.tr('service.ConsumptionLiters') },
      { title: this.t.tr('general.actions') },
    ];
  }
}
